$enable-responsive-font-sizes: true;

$primary: #8e24aa !default;
$primary-light: #c158dc;
$primary-dark: #5c007a;
$secondary: #ffff00 !default;
$secondary-light: #ffff5a;
$secondary-dark: #c7cc00;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 961px,
) !default;

$font-size-base: 1rem !default;

$navbar-brand-font-size: $font-size-base * 2.5;

@import '~bootstrap/scss/bootstrap.scss';

html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: $primary;
}

.nav-underline .active {
  font-weight: 500;
  color: $primary;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.lh-100 {
  line-height: 1;
}
.lh-125 {
  line-height: 1.25;
}
.lh-150 {
  line-height: 1.5;
}

// ############
// Auth Forms
// ############

.form-auth {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

// Date Picker Correction
.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper-extra {
  z-index: $zindex-popover !important;
}

.celebrity-image-help {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.celebrity-profile-image {
  position: relative;
  text-align: center;
}

.celebrity-profile-image:hover .celebrity-image-help {
  display: block !important;
}

.my-toast-container {
  z-index: $zindex-popover;
}

.my-toast {
  width: auto !important;
  max-width: 400px;
}

.search-container {
  z-index: $zindex-modal;
  width: 250px;
}

.search-results-container {
  max-height: 400px;
}

.already-suggested-container {
  z-index: $zindex-modal;
  width: 400px;
}

.font-logo {
  font-family: 'Marck Script';
}

.compare-clear-icon {
  color: theme-color('danger');
}

@media (min-width: 576px) {
  .compare-title-left {
    justify-content: flex-end !important;

    h1 {
      text-align: right;
    }
  }

  .compare-title-right {
    justify-content: flex-start !important;

    h1 {
      text-align: left;
    }
  }
}

.cookie-notification {
  background-color: $secondary-dark;
  color: $primary-dark;
}
